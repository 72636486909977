.rd-expo-dash-c, .rd-dashboard-c{
    display: flex;
    padding: 8px;
    background-color: var(--page-bg-color);
    flex-direction: column;
    min-height: 100vh; 
}
.rd-card-items{
    flex: 0.6;
    display: flex;
    flex-wrap: wrap;
}
.rd-items-gallery {
    display: grid;
    margin: 10px;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}





.rd-items-three {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 20px;
}
.rd-items-gallery .exhibitor-card-c,
.rd-items-three .exhibitor-card-c {
    width: 100%;
}
.rd-dash-content{
    margin-left: 16px;
    position: relative;
}

.meeting-items-card{
    user-select: none;
    position: relative;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: white;
    height: 100%;
    border-radius: 8px;

}
.meeting-items-content{
    margin: 10px;
    position: relative;
    padding: 10px;
    background-color: white;
    border-radius: 12px;
}
/* .meeting-items-content:hover{
    background-color: var(--info-hover-color);
} */
.left-flex-right{
    flex: 1;
    display: flex;
    border-radius: 8px;
}
.left-item-flex{
    background-color: white;
    padding: 16px;
    flex: 1;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(241, 233, 231, 0.5);
    flex-direction: column;
    display: flex;
    justify-content: center;

}
.right-item{
    border-radius: 8px;
    padding: 8px;
    display: flex;
    box-shadow: 0px 4px 8px rgba(241, 233, 231, 0.5);
    flex-direction: column;
    background-color: white;
    margin-left: 12px;
    width: 176px;
}
.dash-list-cont{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(241, 233, 231, 0.5);
    background-color: white;
    height: 100%;
}
.dash-item-flex{
    flex: 1;
}
.meeting-actions{
    display: flex; 
    gap: 0 12px;
    margin: auto;
}
.dash-cards-65-35{
    display: flex;
}
.dash-card-65{
    flex: 0.65;
}
.dash-card-35{
    flex: 0.35;
    padding: 8px;
}
.dash-info-cards{
    display: flex;
    gap: 0 14px;
}
.rd-menu-icon{
    width: 40px; 
    height: 40px;
}
.rd-side-menu{
    position: relative;
    width: 200px;
    min-height: 70vh;
    padding: 12px 4px;
    margin-left: 8px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--accent-dark-bg);
}
.mobile-rd-side-menu{
    position: absolute;
    z-index: 5;
    display: none;
    margin-top: 0;
    width: 200px;
    padding: 12px 4px;
    margin-left: 8px;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: var(--accent-dark-bg);
}
.header-menu-btn{
    align-self: center;
    display: none;
}
.oes-menu-btn{
    display: block;
    margin-left: 6px;
}
.rd-menu-profile-c{
    padding: 8px;
    flex-direction: column;
}
.rd-menu-pic{
    width: 56px;
    height: 56px;
    display: block;
    margin: auto;
    cursor: pointer;
    -webkit-user-drag: none;
    border-radius: 50%;
}
.header-profile-img{
    height: 32px;
    border-radius: 50%;
}
.explore-select-btn{
    color: #6f7e92 !important;
    text-transform: none !important;
    font-weight: 550 !important;
    border: 1px solid #ccc !important;
}
.explore-select-btn:hover{
    color: #00a2d9 !important;
    border: 1px solid #62c5e8 !important;
}
.explore-select-btn.active{
    color: #00a2d9 !important;
    border: 1px solid #62c5e8 !important;
}
.dash-30-70{
    display: flex;
}
.dash-30{
    flex: .3;
}
.dash-70{
    flex: .7;
}
.rd-menu-pic:hover{
    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
}


.sublink-con {
    margin-left: 20px;
    border-radius: 4px;
    transition: display 0.3s ease;
    display: none;
  }
  
  .sublink-con.visible {
    display: block; /* Adjust as necessary */
  }
  .sublink-item {
    color: white !important;
    text-transform: none !important;
    background-color: transparent !important;
  }
  
  .sublink-item:hover {
    background-color: #00a2d9 !important;
  }
  
  .sublink-item:active {
    background-color: #00a2d9 !important;
  }
  
  .sublink-item .MuiSvgIcon-root {
    color: #00a2d9;
  }
  
  .sublink-item:hover .MuiSvgIcon-root {
    color: white !important;
  }
  
  .sublink-item:active .MuiSvgIcon-root {
    color: white !important;
  }  

.rd-img-group{
    width: 40px;
    height: 40px;
    cursor: pointer;
    -webkit-user-drag: none;
    border: 2px solid white;
    border-radius: 50%;
}
.rd-img-group.next-img{
    margin-left: -8px;
}
.rd-table-container{
    overflow-x: auto;
}
.rd-table-container table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
}

.rd-table-container th, 
.rd-table-containertd {
    text-align: left;
    padding: 8px;
}
.rd-table-container td{
    border: 1px solid #ddd;
}
.rd-table-container tr:nth-child(even){background-color: #f2f2f2}

.youtube-video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
    position: relative;
    overflow: hidden;
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
.ex-prof-comp-name{
    font-weight: 600;
    flex: 1 1 0%;
    margin: 10px 0;
    text-align: center;
    color: #004490;
}
.ex-floor-loc{
    margin: 0 12px;
}
.ex-floor-loc.ntop{
    margin-top: -45px;
}
.ex-name-cont{
    display: flex;
    margin: 16px 0;
}
.product-info-c{
    display: flex;
    margin: 8px 0;
    max-width: 1280px;
}
.exp_date_pciker{
    margin-top: 16px !important;
    margin-bottom: 12px !important;
}
.exp_date_pciker input {
    padding: 8px !important;
}
.exp_date_pciker label{
    top: -8px !important;
}
.product-info-c .graphics-area{
    flex: 0.4;
}
.product-info-c .text-area{
    flex: 0.6;
    padding: 0 16px;
}
.product-item{
    cursor: pointer;
    max-width: 300px;
    border-radius: 10px;
    position: relative;
    background-color: white;
    border: 2px solid #fcf7f5;
    transition: background-color 0.3s ease, box-shadow 0.3s ease; /* This will make the hover transition smoother */
}

.product-item:hover {
    border: 2px solid  #e5e2e2;
    background-color: #e5e2e2; /* A slightly darker shade than #f0eded */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* A subtle shadow effect to give a lifted appearance */
}
.product-item-name{
    font-weight: 600;
    max-width: 300px;
    height: 36px;
    padding-left: 8px;
    padding-right: 8px;
    display: block;
    color: #3063a1;
    margin: 10px auto 8px auto;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-image-cont {
    height: 120px;
    margin: 8px;
    padding: 8px;
    background-color: white;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.product-image-cont img {
    border-radius: 4px;
    max-width: 100%; 
    max-height: 100%;
}
.profile-info-c{
    margin-top: 16px;
    padding: 14px;
    background-color: white;
    border-radius: 8px;   
}
.brochure-item-img{
    border-radius: 4px;
    height: 100px;
    display: block;
    margin: 4px;
    width: calc(100% - 8px);
    object-fit: cover;
}
.product-item-action{
    display: flex;
    margin: 8px;
    padding-top: 4px;
    border-top: 0.9px solid #cfbbbb;
}
.team-member{
    width: 166px;
}
.team-member-img{
    position: relative;
    display: block;
    margin: 12px auto auto auto;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 1px solid rgb(182, 131, 55);
    cursor: pointer;
}
.explore-tabs-holder{
    display: flex;
    padding: 8px;
    background-color: white;
    border-radius: 4px;
    margin: 8px;
    gap: 12px;
}
.items-not-found{
    margin: 16px;
    font-size: 20px;
    color: #888;
    font-weight: 600;
}

@media screen and (min-width: 1200px) {
    .rd-items-gallery {
        grid-template-columns: repeat(4, 1fr);
    }
    .rd-items-three {
        grid-template-columns: repeat(3, 1fr);
    }
}
@media only screen and (max-width: 1000px) {
    .dash-cards-65-35, .dash-30-70{
        flex-direction: column;
    }
    .dash-card-65, .dash-card-35, .dash-30, .dash-70{
        flex: 1;
    }
}
@media only screen and (max-width: 780px) {
    .left-flex-right{
        flex-direction: column;
    }
    .right-item{
        width: auto;
        margin-left: initial;
        margin-top: 16px;
    }
    .dash-card-65, .dash-info-cards{
        display: flex;
        flex-direction: column;
    }
    .meeting-actions{
        margin-top: 20px;
    }
    .dash-info-cards{
        gap: 20px;
    }
    .dash-item-flex{
        margin-top: 20px;
    }
    .ex-prof-comp-name{
        font-size: 14px;
        margin: auto 12px;
        flex-direction: column;
    }
    .ex-name-cont{
        flex-direction: column;
    }
    .ex-floor-loc.ntop{
        margin-top: 0;
    }
}
@media only screen and (max-width: 500px){
    .explore-tabs-holder{
    flex-direction: column;
    }
    .rd-side-menu{
        display: none;
    }
    .hd-1-item img{
        display: none;
    }
    .rd-menu-icon{
        width: 32px; 
        height: 32px;
    }
    .dash-30-70{
        padding: 0;
    }
    .expp-dash-header-c .hd-1-item {
        width: initial !important;
    }
    .rd-dash-content{
        margin-left: initial;
    }
    .header-menu-btn{
        display: block;
        margin-left: 6px;
    }
}