.expp-dash-header-c {
    display: flex;
    top: 0;
    width: 100%; /* Set full width */
    padding: 6px; /* Optional: Increase horizontal padding for better spacing */
    position: fixed;
    border-radius: 0; /* Remove border-radius to cover full width */
    background-color: white;
    box-shadow: 0 3px 6px rgba(246, 245, 245, 0.16), 0 3px 6px rgba(112, 111, 111, 0.23);
    z-index: 101; /* Ensure it stays above other content */
}
.expp-dash-header-c .hd-1-item{
    display: flex;
    width: 192px;
    justify-content: center;
}
.expp-dash-header-c .hd-1-item img{
    height: 64px;
}
.expp-dash-header-c .hd-2-item{
    display: flex;
    align-items: center;
}