.regHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 8px;
  background-size: cover;
  background-position: center;
  background-color: white;
  position: relative;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.regHeader__item, .regHeader__middle {
  display: flex;
  justify-content: center;
  align-items: center;
}

.regHeader__item img, .regHeader__middle img {
  max-width: 100%;
  max-height: 100px;
}

.regHeader__item {
  flex: 1;
}

.regHeader__middle {
  flex: 2; /* Middle image takes twice as much width */
}

@media (max-width: 500px) {
  .regHeader__item img, .regHeader__middle img {
    width: 100%; /* Or any suitable value that makes sure images are scaling and look good */
  }
}
