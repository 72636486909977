.exhibitor-card-c{
    display: inline-flex;
    flex-direction: column;
    background-color: white;
    padding: 8px;
    margin: 8px;
    width: 200px;
    border: 0.7px solid #ddd;
    border-radius: 10px;
    transition: all 0.3s ease-in-out; /* This will animate any changes */
}

.exhibitor-card-c:hover {
    transform: translateY(-4px); /* Moves the card 10px up */
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Enlarges the shadow */
    background-color: #f0f0f0; /* Changes the background color on hover */
}
.visitor-card{
    width: 176px !important;
}

.exhibitor-card-c .ex-img-left{
    height: 100px;
    object-fit: contain; 
    border-radius: 8px;
}
.name-two-lines{
    font-weight: 600;
    color: #4773ac;
    font-size: 15px;
    height: 38px;
    margin-top: 12px;
  user-select: none;
  cursor: pointer;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
