.reg-header {
    padding: 12px;
    background-image: url(../../assets/header_bg.png);
    background-size: cover;
    background-position: center;
    background-color: white;
    position: relative;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .reg-header-content{
    display: flex;
    align-items: center;
    max-width: 1280px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
  }
  

  .reg-header-item img, reg-header-m-logo {
    height: 100px;
    display: block;
  }
  
  .reg-header-middle {
    flex: 1;
    text-align: center;
  }
  .reg-header-middle img {
    display: block;
    margin: auto;
  }
  
  .reg-header-m-logo {
    height: 100px;
    margin-left: auto;
    display: block;
  }

  .newhcontainer {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    background-size: cover;
    background-repeat: no-repeat;
}

.newhheader-image {
    width: auto;
    display: block;
    margin: auto;
}

/* Example Media Query for smaller screens */
@media (max-width: 600px) {
    .newhcontainer {
        height: 100px;
    }
    .newhheader-image {
        max-height: 90px;
        width: 90%;
    }
}



  
  @media (max-width: 768px) {
    .reg-header-content{
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    .reg-header-middle img{
        width: 100%;
    }
    .reg-header-item,
    .reg-header-middle {
      flex: none;
      width: 100%;
      margin-bottom: 10px;
    }
    .rearranged-item{
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 8px;
        padding-right: 8px;
    }
    .reg-header-item img{
        height: 76px;
    }
  }




  