.expp-login-c{
    display: flex;
    flex-direction: column;
    min-height: 100vh; 
}
.expp-login-c .expp-login-a{
    max-width: 1180px;
    margin: 8px auto;
    padding: 8px;
    width: 100%;
}
.expp-login-ca{
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100vh; 
    background-color: white;
}
.login-box{
    display: flex;
    max-width: 1200px;
    width: 100%;
    max-height: 600px;
    height: 100%;
    box-shadow: -2px 2px 26px 34px #f3f3f3;
}
.login-flex-left, .login-flex-right{
    flex: 1;
}
.login-action-box{
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 38px 0;
    max-width: 450px;
    margin: auto;
}
.login-select-c{
    display: flex;
    gap: 16px;
}
.login-select-c .MuiButton-outlined{
    flex: 1;
    border-radius: 0 !important;

}
.login-action-box .MuiOutlinedInput-root,
.login-action-box .MuiButton-contained{
    border-radius: 0 !important;
}
.login-top-right-icon{
    height: 18px; 
    align-self: center;
}
.top-line{
    flex: 1;
    font-size: 17px;
}
@media only screen and (max-width: 720px) {
    .login-box{
        flex-direction: column;
        margin: 16px;
    }
    .login-action-box{
        padding: 20px;
    }
    .login-select-c{
        gap: 4px;
    }
    .top-line{
        font-size: 20px;
        text-align: center;
    }
    .login-top-right-icon{
        display: none;
    }
}
