.rd-menu-button{
    display: flex;
    color: var(--dark-bg-text-color);
    font-size: 14px;
    font-weight: 500;
    padding: 7px;
    margin: 10px 0;
    border-radius: 8px;
    font-family: 'DM Sans','Open Sans',Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
    cursor: pointer
}
.side-menu-icon{
    font-size: 16px !important;
    margin-right: 8px;
    margin-left: 6px;
    color: var(--color-accent);
}
.rd-menu-button:hover{
    background-color: var(--color-accent);
}
.rd-menu-button:hover .side-menu-icon {
    color: var(--dark-bg-text-color);
}
.rd-menu-button.active{
    background-color: var(--color-accent);
}
.rd-menu-button.active .side-menu-icon{
    color: white;
}