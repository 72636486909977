.ql-excel{
    height: 85vh;
    overflow-y: auto;
    display: flex;
}
.ql-excel-i{
  align-self: center;
  display: flex;
  width: 100%;
}
.ql-excel-i-c{
  flex: 0.7;
  padding-left: 16px;
}
.ql-excel-i-c ul li{
  margin-top: 12px;
}
.selected_qns{
    padding: 8px;
    overflow-x: auto;
    margin-top: 16px;
}
  .ql-excel-analytics{
    display: table;
  }
.ql-excel-btn-s{
  display: table;
  margin-left: auto;
  margin-right: auto;
  margin-top: 16px;
  margin-bottom: 16px;
}
.ql-excel-btn-s .MuiButton-contained{
    width: 192px;
    border-radius: 12px;
    
}
.selected_qns tr:nth-child(even){background-color: #f2f2f2}
.excel-w-c{
    margin-top: 24px;
    display: table;
    margin-left: auto;
    margin-right: auto
}
.excel-onfile-sel{
    display: block;
    border: 2px dashed #888;
    border-radius: 16px;
    margin-top: 18px;
    cursor: pointer;
    user-select: none
}