.imtma-footer{
    background-color: #054054;
    height: 60px;
    color: white;
    display: flex;
    align-items: center;
}
.footer-logo{
    margin-right: 10px;
    height: 24px;
    margin-left: 60px
}
.footer-text{
    font-size: 16px;
    margin-left: auto;
    margin-right: 60px;
}
@media only screen and (max-width: 600px) {
    .imtma-footer{
        flex-direction: column;
    }
    .footer-logo{
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
    .footer-text{
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }
}