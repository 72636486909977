/* Safari */
@-webkit-keyframes rdSpin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes rdSpin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  