.rdw-editor-main{
    margin-bottom: 16px !important;
    padding: 12px !important;
    border: 1px solid #f0f0f0 !important;
    border-radius: 6px !important;
    min-height: 90px !important;
    max-height: 176px !important;
    overflow-y: auto;
    background-color: rgb(241, 241, 241);
  }
  .public-DraftStyleDefault-block{
    margin: 0 !important;
  }
  .rdw-link-modal, .rdw-colorpicker-modal, .rdw-image-modal, .rdw-emoji-modal{
    border-radius: 16px !important;
    box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%) !important;
  }
  .rdw-editor-toolbar{
     border-radius: 6px !important;
     border: 1px solid #ddd;
  }
  .rdw-embedded-wrapper, .rdw-image-wrapper, .rdw-link-wrapper, .rdw-remove-wrapper, .rdw-text-align-wrapper, .rdw-list-wrapper, .rdw-fontfamily-wrapper{
     display: none !important;
  }