.expo-table {
    border-collapse: collapse;
    margin: 16px 0;
    font-size: 16px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
  }
  
  .expo-table th,
  .expo-table td {
    padding: 8px 12px;
  }
  
  .expo-table thead {
    background-color: #054059;
    color: #ffffff;
  }
  
  .expo-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .expo-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .expo-table tbody tr:last-of-type {
    border-bottom: 2px solid #246355;
  }
  
  .expo-table tbody tr:hover {
    background-color: #eaeaea;
  }
  
  .expo-table td img {
    width: 50px;
    height: 50px;
    object-fit: contain;
  }

@media screen and (max-width: 768px) {
    .expo-table {
      width: 98%;
    }
    
    .expo-table thead {
      display: none; /* Hide table headers */
    }
    
    .expo-table tr {
      margin-bottom: 0.625rem;
      display: block;
      border: 1px solid #ddd;
    }
    
    .expo-table td {
      display: block; /* Stack td elements */
      text-align: right;
      padding-left: 50%; /* Create a space for the label */
      position: relative;
    }
    
    .expo-table td::before {
      content: attr(data-label); /* Insert the data-label attribute */
      position: absolute;
      left: 0;
      width: 50%;
      padding-left: 15px;
      font-size: 15px;
      font-weight: bold;
      text-align: left;
    }
    
    .expo-table td img {
      display: inline-block;
      margin-right: 10px;
    }
  }
  
  