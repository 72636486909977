.modal {
  position: fixed;
  z-index: 1300;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex; /* Add this line */
  justify-content: center;
  align-items: center; /* Add this line */
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0; /* Start with opacity 0 */
}


  /* Modal Content */
  .modal-content {
    padding: 0;
    position: relative;
    align-self: center;
    display: flex;
    flex-direction: column;
    border-radius: 12px;
    background-color: #ffffff;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  }
  .modal-header {
    display: flex;
    color: rgb(131, 127, 127);
  }
  .modal-title{
    flex: 1;
  }
  
  .modal-body {padding: 2px 16px;  background-color: white;}
  
  .modal-footer {
    padding: 2px 16px;
    color: rgb(139, 136, 136);
  }
  
 
@keyframes showa {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes hidea {
  from { opacity: 1; }
  to { opacity: 0; }
}
  
  /* The Close Button */
  .close {
    color: rgb(94 91 91);
   position: absolute;
   bottom: -29px;
   left: 50%;
   transform: translateX(-50%);
   width: 32px;
   height: 32px;
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   user-select: none;
   -webkit-user-select: none;
   background-color: #ebe7e7;
    font-size: 28px;
    font-weight: bold;
  }
  
  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
  .modal-c-body{
    padding: 8px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .modal-show {
    animation: showa 0.6s forwards;
  }
  
  .modal-hide {
    animation: hidea 0.6s forwards;
  }
  