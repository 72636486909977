
:root {
  --primary-color: #054054;

  --color-accent: #00A2D9;
  --accent-dark-bg: #222b40;
  --dark-bg-text-color: white;
  --page-bg-color: #fcf7f5;
  
  --primary-hover-color: #145d75;
  --side-nav-width: 210px;
  --side-nav-min: 70px;
  --menu-transition-time: 1s;
  --info-hover-color: azure;
  --font-size: 16px;
  --bg-color: #f5f5f5;
}
*{
  box-sizing: border-box;
}
.slick-prev, .slick-next {
  color: rgb(75, 71, 71) !important;
  background-color: rgb(207 206 205) !important;
  border-radius: 50% !important;
}
.slick-prev{
  right: -20px !important;
}
.slick-next {
  right: -20px !important;
}
.PhoneInputInput {
  border: 1px solid #ccc;
  border-radius: 6px;
  color: rgba(0,0,0);
  font-family: Roboto,Helvetica,Arial,sans-serif;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: .00938em;
  line-height: 1.4375em;
  outline: none;
  padding: 8px;
}
.PhoneInputInput:focus {
  border-color: #4a90e2; /* Change to your preferred focus border color */
  box-shadow: 0 0 5px rgba(74, 144, 226, 0.5); /* Adds a slight glow around the input on focus */
}
/* .App{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;  
  min-height: 100vh; 
} */
.three-lines-clamp{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.responsive-content {
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  position: relative;
  border-radius: 8px;      /* Add rounded corners */
  overflow: hidden;        /* Hide anything outside the rounded corners */
}

.responsive-content iframe,
.responsive-content video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media print {
  @page {
    size: A4;
    /* margin: 10mm 54.5mm 165mm 52mm;  */
    margin-top: 8mm;  
  }
  .no-print {
    display: none;
  }
}



