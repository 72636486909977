/* General Styles */
.badge-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
    background-color: #f0f0f0; /* Light grey background */
  }
  
  .badge {
    width: 9.5cm; /* Adjust to the width of your badge */
    height: 14cm; /* Adjust to the height of your badge */
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5cm;
    background-color: white;
    text-align: center;
  }
  
  .badge h3 {
    margin-top: 10px;
    font-size: 1.2em;
  }
  
  .badge p {
    font-size: 1em;
    margin-top: 5px;
  }
  
  /* Print Styles */
  @media print {
    body {
      margin: 0;
      padding: 0;
      background-color: #fff; /* Ensure the background is white when printing */
    }
  
    .badge-container {
      padding: 0;
    }
  
    .badge {
        width: 9.5cm; /* Adjust to the width of your badge */
        height: 14cm; /* Adjust to the height of your badge */
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 5cm;
      }
  
    .badge h3, .badge p {
      margin-top: 0; /* Remove margin for printing */
    }
  
    button {
      display: none; /* Hide button when printing */
    }
  }
  