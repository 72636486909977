.exp-user-basic-c{
    display: flex;
    margin-top: 50px;
}
.exp-user-basic-c .exp-user-info{
    flex: 1;
    margin-left: 16px;
}
.rd-custom-button {
    display: inline-block;
    font-size: 1em;
    padding: 8px 20px;
    margin-right: 8px;
    color: #fff;
    background-color: #007BFF;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease 0s;
    cursor: pointer;
    outline: none;
}

.rd-custom-button:hover {
    background-color: #0069D9;
    box-shadow: 0px 15px 20px rgba(0, 123, 255, 0.4);
    color: #fff;
    transform: translateY(-7px);
}